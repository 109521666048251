import React, { createContext, useContext, useMemo } from 'react';

interface DropdownContextValue {
  /** Whether the dropdown is currently open or not. */
  isOpen: boolean;
}

/**
 * Allows child content components of `<Dropdown>` to be aware of its parent's
 * state. For example, a child may need to know whether its dropdown is
 * currently toggled open or closed.
 */
const DropdownContext = createContext<DropdownContextValue | null>(null);
DropdownContext.displayName = 'DropdownContext';

export const DropdownContextProvider: React.FC<DropdownContextValue> = ({ children, isOpen }) => {
  return (
    <DropdownContext.Provider
      value={useMemo(
        () => ({
          isOpen,
        }),
        [isOpen],
      )}
    >
      {children}
    </DropdownContext.Provider>
  );
};

/**
 * Allows child content components of `<Dropdown>` access to its parent's
 * dropdown state. For example, a child component may need to know whether its
 * dropdown is currently toggled open or closed.
 * @example
 * ```ts
 * const { isOpen } = useDropdownContext();
 *
 * useEffect(() => {
 *   if (isOpen) {
 *     // run some initialization
 *   }
 * }, [isOpen]);
 * ```
 */
export function useDropdownContext() {
  const value = useContext(DropdownContext);
  if (!value) throw new Error('DropdownContext.Provider is missing');
  return value;
}
