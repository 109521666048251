/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import classy from '@core/utils/classy';

import Box from '@ui/Box';

import './style.scss';

type Props = React.HTMLAttributes<HTMLElement> & {
  TagName?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  theme?: 'dark' | 'light';
};

const Menu = React.memo(
  React.forwardRef<HTMLElement, Props>(
    ({ children, className = '', theme = 'light', TagName = 'div', ...attrs }: Props, ref) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const _className = useMemo(() => classy('Menu', `Menu_${theme}`, className), [className]);

      return (
        <Box className={_className} kind="pop" Tag={TagName} theme={theme} {...attrs} ref={ref}>
          {children}
        </Box>
      );
    },
  ),
);

export { default as MenuDivider } from '@ui/Menu/Divider';
export { default as MenuHeader } from '@ui/Menu/Header';
export { default as MenuItem } from '@ui/Menu/Item';

export default Menu;
