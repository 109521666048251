import type Oas from 'oas';

import { useState, useEffect } from 'react';

/**
 * Dereferences an API definition and returns the dereferenced OAS instance.
 * Yes, we dereference the OAS on the client. Not ideal!
 *
 * @param apiDefinition The OpenAPI definition to dereference
 *
 * @deprecated This hook is [not reliable](https://readmeio.slack.com/archives/C089VLEECKC/p1740014122926289)
 * and is only used in pre-superhub code.
 * In superhub, we handle all dereferencing on the server instead.
 * Try to avoid using it in any new code when possible.
 * It should be removed if/when we're completely migrated to superhub.
 */
export default function useDereference(apiDefinition: Oas) {
  // @ts-expect-error - `dereferencing` is a valid property on `Oas`
  const [isDereferenced, setIsDereferenced] = useState(apiDefinition?.dereferencing?.complete || false);

  // resets the dereferenced state when the API definition changes
  useEffect(() => {
    if (apiDefinition?.api?._id) {
      setIsDereferenced(false);
    }
  }, [apiDefinition?.api?._id]);

  useEffect(() => {
    let isCanceled = false;

    async function dereference() {
      try {
        await apiDefinition.dereference();
      } catch (e) {
        // no-op
      }
      if (!isCanceled) setIsDereferenced(true);
    }

    if (!isDereferenced) dereference();

    return () => {
      isCanceled = true;
    };
  }, [isDereferenced, apiDefinition?.api?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isDereferenced };
}
