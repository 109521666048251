import PropTypes from 'prop-types';
import React from 'react';

import { asNumber, guessType, hasSchemaType, shouldAlwaysUseDefaults } from '../../utils';

import classes from './style.module.scss';

const nums = new Set(['number', 'integer']);
const PLACEHOLDER_VALUE = 'placeholder';

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
function processValue(schema, value) {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;

  // Nullable parameters
  if (Array.isArray(type) && type.includes('null')) {
    if (value === 'null') {
      return null;
    }

    if (type.includes('boolean')) {
      return value === '' ? undefined : value === 'true';
    }
    return value;
  }

  if (value === '') {
    return undefined;
  } else if (type === 'array' && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === 'boolean' || hasSchemaType(schema, 'boolean')) {
    return value === 'true';
  } else if (type === 'number' || hasSchemaType(schema, 'number')) {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every(x => guessType(x) === 'number')) {
      return asNumber(value);
    } else if (schema.enum.every(x => guessType(x) === 'boolean')) {
      return value === 'true';
    }
  }

  return value;
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter(o => o.selected)
      .map(o => o.value);
  }

  return event.target.value;
}

function SelectWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props;
  const { enumOptions, hideEmpty } = options;
  const emptyValue = multiple ? [] : '';

  const hasPlaceholder = 'placeholder' in props;

  const normalizedPlaceholder = hasPlaceholder ? String(placeholder) : '';

  // If our placeholder (what is really our example value) already exist within the enum of available options to select
  // from, we shouldn't also show the placeholder because we'll end up rendering that option twice. RM-1264
  const hidePlaceholder = enumOptions.length && enumOptions.filter(opt => opt.value === placeholder).length;

  const alwaysUseDefaults = shouldAlwaysUseDefaults(props.formContext || {});
  const singleOptionalNonEmptyEnum = !required && !multiple && !enumOptions.filter(({ value }) => value === '').length;

  // Edge Case: If there is only 1 enum option and alwaysUseDefaults is off, we don't automatically add that
  // option into the form because we prioritize `alwaysUseDefaults` over if the parent object is required or
  // optional. However, it's considered selected in the widget. This causes the option to be unselectable.
  // We want to add an empty option so users can add the option to the form.
  const singleRequiredUnusedDefaultEnum = required && !alwaysUseDefaults && schema.enum?.length === 1;

  const showEmptyOption =
    (schema.enum || schema.enumNames) && !hideEmpty && (singleOptionalNonEmptyEnum || singleRequiredUnusedDefaultEnum);

  const showPlaceholder =
    !!normalizedPlaceholder && (value === undefined || value === '' || value === PLACEHOLDER_VALUE);

  let selectValue;

  if (showPlaceholder) {
    selectValue = PLACEHOLDER_VALUE;
  } else if (value === null) {
    selectValue = 'null';
  } else if (typeof value === 'undefined') {
    selectValue = emptyValue;
  } else {
    selectValue = value;
  }

  return (
    <select
      autoFocus={autofocus}
      className={`Select Select_sm ${classes['Param-select']} rm-ParamSelect${
        showPlaceholder ? ' Select_placeholderShown' : ''
      }`}
      disabled={disabled || readonly}
      id={id}
      multiple={multiple}
      onBlur={
        !!onBlur &&
        (event => {
          const newValue = getValue(event, multiple);
          onBlur(id, processValue(schema, newValue));
        })
      }
      onChange={event => {
        const newValue = getValue(event, multiple);
        onChange(processValue(schema, newValue));
      }}
      onFocus={
        !!onFocus &&
        (event => {
          const newValue = getValue(event, multiple);
          onFocus(id, processValue(schema, newValue));
        })
      }
      required={required}
      value={selectValue}
    >
      {!!normalizedPlaceholder && !multiple && value === undefined && !hideEmpty && (
        <option disabled hidden={hidePlaceholder} value={PLACEHOLDER_VALUE}>
          {normalizedPlaceholder}
        </option>
      )}

      {!!showEmptyOption && <option value=""></option>}

      {enumOptions.map(({ value, label }, i) => {
        return (
          <option key={i} value={value}>
            {label}
          </option>
        );
      })}
    </select>
  );
}

SelectWidget.defaultProps = {
  autofocus: false,
};

SelectWidget.propTypes = {
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    hideEmpty: PropTypes.bool,
  }).isRequired,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  schema: PropTypes.object.isRequired,
  value: PropTypes.any,
};

export default SelectWidget;
