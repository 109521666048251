import { FullStory, init as initFullStory } from '@fullstory/browser';
import { useContext, useEffect } from 'react';

import type { ConfigContextValue, UserContextValue } from '@core/context';
import { ConfigContext, UserContext } from '@core/context';

export default function useFullStory() {
  const {
    fullstory: { enabled, orgId },
  } = useContext(ConfigContext) as ConfigContextValue;
  const { user, _id } = useContext(UserContext) as unknown as UserContextValue;
  const { is_god: isGod } = useContext(UserContext);

  // Enable fullstory only for users that are not god in a prod environment
  const isEnabled = !isGod && enabled;

  useEffect(() => {
    if (isEnabled) {
      // If we are missing events, move this outside the hook so it loads faster.
      // also set startCaptureManually: true and call FullStory('start') capturing manually once we determine the user is an admin.
      initFullStory({
        orgId,
      });
      if (_id && user?.name) {
        FullStory('setIdentity', {
          uid: _id || 'unknown',
          properties: {
            displayName: user?.name || 'unknown',
            email: user?.email || 'unknown',
          },
        });
      }
    }

    return () => {
      if (isEnabled) {
        FullStory('shutdown');
      }
    };
  }, [_id, isEnabled, orgId, user?.email, user?.name]);

  return { FullStory };
}
