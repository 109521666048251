/**
 * Accepts a user agent string and transforms it into something that is more readable
 */
function getFormattedUserAgent(userAgent) {
  const userAgentString = typeof userAgent === 'string' && userAgent.length ? userAgent.trim() : '';
  // Take everything before the first slash, omitting the version and extraneous information
  const name = userAgentString.split('/')[0];

  if (name === 'ReadMe-API-Explorer') {
    return name.split('-').slice(1).join(' ');
  }

  // Takes the string up to where a dash, underscore, or space is detected
  const prettyUA = name.split(/[\s-_]+/)[0].toLowerCase();

  switch (prettyUA) {
    case 'node':
      return 'Node';
    case 'python':
      return 'Python';
    case 'ruby':
      return 'Ruby';
    case 'php':
      return 'PHP';
    default:
      return userAgentString;
  }
}

export { getFormattedUserAgent };
