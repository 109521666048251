import type { $TSFixMe } from '@readme/iso';

import { getFormattedUserAgent } from '@readme/server-shared/http-helper'; // eslint-disable-line readme-internal/no-restricted-imports
import unzip from 'lodash/unzip';
import React, { useMemo } from 'react';

import type { APIKeyRequestRow } from '@core/types/metrics';
import { getElapsed } from '@core/utils/metrics';

import Method from '@ui/API/Method';
import Badge from '@ui/Badge';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import UITable from '@ui/Table';
import Timestamp from '@ui/Timestamp';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

/*
 * Strip trailing slash from the pathname:
 * - /a/b/c/ -> /a/b/c
 * - / -> /
 * - /https://try.readme.io/a/b/c -> /a/b/c
 */
export function stripTrailingSlash(path: string): string {
  try {
    /*
     * RM-2802 Account for possibly nested urls such as `https://try.readme.io/https://dash.readme.com/api/v1/apply`, which has a path of `/https://dash.readme.com/api/v1/apply`.
     * This specifically happens if we mock a har given using oas-to-har when an api has no auth. Metrics can't accept hars that have no auth/groupId as it has no way to catagorize them.
     * The package `oas-to-har` will prepend `try.readme.io` to the url in order to not get blocked by CORS: https://github.com/readmeio/oas-to-har/blame/b1769de66c31554f8092de51d8798d3e6c7d0e7a/src/index.js#L87
     * Therefore thes URLs will need the leading slash stripped off and to then be recursively processed as a new URL.
     */
    if (path.startsWith('/http')) {
      const { pathname } = new URL(path.substring(1));
      return stripTrailingSlash(pathname);
    }
    // Strip trailing forward slash from path if it exists
    if (path.endsWith('/') && path !== '/') return path.substring(0, path.length - 1);

    return path;
  } catch (e) {
    return path;
  }
}

interface Props {
  children?: React.ReactNode;
  className?: string;
  columnNames: Record<string, string>;
  condensed?: boolean;
  emptyState?: React.ReactNode | string;
  handleRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, key: string | null) => void;
  isLoading?: boolean;
  logs?: APIKeyRequestRow[];
  selectedRowId?: string | null;
}

const Table = ({
  children,
  className,
  columnNames,
  condensed,
  emptyState,
  handleRowClick,
  isLoading,
  logs,
  selectedRowId,
}: Props) => {
  // Breaking the body in to a partial avoids expensive recalculation for each time the selected row changes
  const [bodyPartial = [], bodyKeys = []] = useMemo(() => {
    if (!logs) return [];

    return unzip(
      logs.map(log => {
        // Time
        const timeEl = log?.startedDateTime ? (
          <Timestamp
            formatter={d => getElapsed(d.toISOString(), { includeAgo: true })}
            value={new Date(log.startedDateTime)}
          />
        ) : (
          '-'
        );

        // Status
        const statusEl = (
          <Flex align="center" gap="2px" justify="start">
            <HTTPStatus className={classes['LogsTable-td_status']} iconOnly status={log.status} />
            {log.status}
          </Flex>
        );
        // Method
        const methodEl = <Method className={classes['LogsTable-td_method']} fixedWidth type={log.method} />;
        // Path
        const path = stripTrailingSlash(log.path);
        const pathEl = <span title={log.url}>{path}</span>;
        // User
        const user = log.groupLabel || log.groupEmail;
        const userEl = (
          <>
            <span title={user}>{user}</span>
            {!!log.groupLabel && !!log.groupEmail && (
              <span className={classes['LogsTable-user']} title={log.groupEmail}>
                {log.groupEmail}
              </span>
            )}
          </>
        );
        // User Agent
        const { name, platform } = log?.useragent || {};
        const formattedUA = platform || getFormattedUserAgent(name) || '-';

        let userAgentEl = (
          <span className={classes['LogsTable-td_useragent']} title={formattedUA}>
            {formattedUA}
          </span>
        );

        // Show Try It requests as a badge
        if (name === 'ReadMe-API-Explorer') {
          userAgentEl = (
            <span className={classes['LogsTable-td_useragent']}>
              <Badge kind="info">Try It!</Badge>
            </span>
          );
        }

        const row: Record<string, React.ReactNode> = {};

        if (columnNames.TIME) row[columnNames.TIME] = timeEl;
        if (columnNames.STATUS) row[columnNames.STATUS] = statusEl;
        if (columnNames.METHOD) row[columnNames.METHOD] = methodEl;
        if (columnNames.PATH) row[columnNames.PATH] = pathEl;
        if (columnNames.USER) row[columnNames.USER] = userEl;
        if (columnNames.USER_AGENT) row[columnNames.USER_AGENT] = userAgentEl;

        // Key names correspond to column names
        return [row, log.id];
      }),
    );
  }, [
    columnNames.PATH,
    columnNames.STATUS,
    columnNames.METHOD,
    columnNames.TIME,
    columnNames.USER,
    columnNames.USER_AGENT,
    logs,
  ]);

  // This is what is what is calculated when a new row is selected, instead of expensively regenerating the entire body
  const body = useMemo(() => {
    if (!logs) return [];
    return logs.reduce((acc, log, i) => {
      const isSelected = selectedRowId === log.id;

      const row = {
        ...(acc[i] as Record<string, $TSFixMe>),
        [columnNames.ICON]: (
          <Tooltip content="Inspect Request">
            <Icon name={isSelected ? 'check' : 'eye'} />
          </Tooltip>
        ),
        _className: isSelected ? classes['LogsTable-row_selected'] : '',
      };

      acc[i] = row;

      return acc;
    }, bodyPartial);
  }, [columnNames, logs, bodyPartial, selectedRowId]);

  const tableHeaders = useMemo(
    () => [
      body[0]
        ? Object.keys(body[0]).filter(k => !k.startsWith('_')) // Filter out meta properties like `_className`
        : // Even if there's no data for the body, we still want to show the header and have to construct this object as a result
          Object.values(columnNames),
    ],
    [columnNames, body],
  );

  const emptyStateEL = useMemo(
    () => (
      <td className={classes.APILogsTable_empty} colSpan={tableHeaders[0].length}>
        {emptyState}
      </td>
    ),
    [emptyState, tableHeaders],
  );

  return (
    <UITable
      body={body}
      bodyKeys={bodyKeys}
      className={`
        ${classes.LogsTable}
        ${!logs?.length ? classes.LogsTable_empty : ''}
        ${className || ''}
      `}
      emptyStateFallback={emptyStateEL}
      head={tableHeaders}
      isCondensed={condensed}
      isDataGrid={true}
      isLoading={isLoading}
      onRowClick={handleRowClick}
    >
      {children}
    </UITable>
  );
};

export default Table;
