/**
 * @DEPRECATED this pattern overly complicates what should be a simple fetch call.
 */
import Cookie from 'js-cookie';
import { useState, useEffect } from 'react';

export function fetchWrapper(baseUrl, { path, headers = {}, ...options }) {
  const host = baseUrl !== '/' ? baseUrl : '';

  return fetch(`${host}/${path}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
      ...headers,
    },
  }).then(response => {
    if (response.ok) {
      const contentType = response.headers.get('Content-Type');

      if (contentType?.includes('application/json')) return response.json();
      return response.text();
    }
    return response.text().then(err => {
      throw new Error(err);
    });
  });
}

function useReadmeApi(baseUrl, initialResponse = {}, isDisabled = false) {
  const [pendingRequest, setPendingRequest] = useState(false);
  const [request, initRequest] = useState({});
  const [response, setResponse] = useState(initialResponse);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(request).length && !isDisabled) {
      const { transformResponse, ...requestOptions } = request;
      setIsLoading(true);
      fetchWrapper(baseUrl, requestOptions)
        .then(json => {
          const res = transformResponse ? transformResponse(json) : json;
          setPendingRequest(!!res.success);
          setResponse(res);
        })
        .catch(({ message }) => {
          setError(message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    setIsLoading(false);
  }, [request, baseUrl, isDisabled]);

  useEffect(() => {
    if (error && process.env.NODE_ENV !== 'production') {
      // Log network errors, except in production
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [error]);

  return { response, error, setError, pendingRequest, initRequest, isLoading };
}

/**
 * @deprecated Use the newer `@core/hooks/useReadmeApi` hook instead.
 */
export default useReadmeApi;
