import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { ReadStagedAPIDefinitionType } from '@readme/api/src/mappings/apis/types';
import type { OASDocument, PathsObject } from 'oas/types';

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import type Modal from '@ui/Modal';
import { RHFGroup } from '@ui/RHF';

import { useSignupFormContext } from '../../context';
import styles from '../SignupForm/style.module.scss';

import ErrorModal from './ErrorModal';

// Catch-all error for non-validation errors on OAS upload/import
const GENERAL_UPLOAD_ERROR = 'GENERAL_UPLOAD_ERROR';

/**
 * This function is a client-side friendly mirror of the one in public/js/factories/APISetting.js
 */
function fixGitHubLinks(url: string) {
  // A lot of people will link to a public OAS file, but it's really an HTML page on GitHub.
  // For example, https://github.com/monikaavalur/api-docs/blob/main/openapi/openapi.yaml

  // This just magically-ish replaces it with the raw version
  let updatedUrl = url;

  // But if it does...
  if (url.match(/\/\/github.com\/[-_a-zA-Z0-9]+\/[-_a-zA-Z0-9]+\/blob\/(.*).(yaml|json|yml)/)) {
    updatedUrl = updatedUrl.replace(/\/\/github.com/, '//raw.githubusercontent.com');
    updatedUrl = updatedUrl.replace(/\/blob\//, '/');
  }

  return updatedUrl;
}

interface SuccessfulUploadResponse {
  _id: string;
  title: string;
}

interface ValidationSuccessResponse {
  info: {
    description: string;
    title: string;
    version: string;
  };
  jsonSchemaDialect?: string;
  openapi: string;
  paths: PathsObject;
  servers: {
    url: string;
  }[];
}

interface ErrorResponse {
  docs: string;
  error: string;
  help: string;
  message: string;
  poem: string[];
  suggestion: string;
}

interface Props {
  isSuperHub: boolean;
  onSkip: () => void;
  onSuccess: ({ type, id }: { id?: string; type: string }) => void;
  projectAPIKey: string;
  projectSubdomain: string;
}

const UploadOASForm = ({ onSkip, onSuccess, projectAPIKey, projectSubdomain, isSuperHub }: Props) => {
  const bem = useClassy(styles, 'SignupForm');
  const [type, setType] = useState('');
  const [uploadError, setUploadError] = useState<Partial<ErrorResponse & ErrorType> | null>(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const { clearErrors, control, handleSubmit, trigger, setValue } = useSignupFormContext();

  const hostedURL = useWatch({ control, name: 'hostedURL' });
  const oasFile = useWatch({ control, name: 'oasFile' });
  const docsPreview = useWatch({ control, name: 'docsPreview' });

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const errorModalRef = useRef<Modal>(null);

  const authHeader = useMemo(() => {
    return `Basic ${Buffer.from(`${projectAPIKey}:`).toString('base64')}`;
  }, [projectAPIKey]);

  const onSubmit = handleSubmit(data => {
    onSuccess({
      type,
      id: data.successfulUploadId,
    });
  });

  const handleUploadClick = useCallback(() => {
    uploadInputRef?.current?.click();
  }, [uploadInputRef]);

  const validateAndUploadAPIv2 = useCallback(
    async ({ file, url }: { file?: File; url?: string }) => {
      setIsUploadLoading(true);
      setUploadError(null);
      clearErrors();

      const uploadType = file ? 'upload' : 'hosted';

      // Set the type of upload in state for form error and success handling
      setType(uploadType);

      try {
        const payload = file ? { schema: file } : { url };
        // Convert payload into multipart form data to send as our request body.
        const formData = Object.entries(payload).reduce((data, [key, value]) => {
          data.set(key, value);
          return data;
        }, new FormData());

        const fetchOptions = {
          body: formData,
          method: 'POST',
          isFormData: true,
        };

        // Validate OAS file first and generate preview
        const validationResponse = await fetcher<{ schema: OASDocument }>(
          `/${projectSubdomain}/api-next/v2/validate/api`,
          fetchOptions,
        );

        setIsUploadLoading(false);

        if (validationResponse) {
          // Valid OAS specs will return spec so we can use it to preview in MockHub
          // Note: we set the ID to a random number here as we don't have an actual ID yet
          setValue('docsPreview', {
            ...validationResponse.schema,
            _id: Math.random(),
          });
        }

        // Proceed with uploading OAS file if there are no errors on validation
        try {
          const response = await fetcher<ReadStagedAPIDefinitionType>(
            `/${projectSubdomain}/api-next/v2/versions/1.0/apis`,
            fetchOptions,
          );

          // Set successful upload ID to form state so MockHub can fetch categories when ready
          setValue('successfulUploadId', response?.data.uri);
        } catch (error) {
          // Do nothing, we'll silently fail any upload errors
        }
      } catch (error) {
        const { info, status } = error as HTTPError;

        setIsUploadLoading(false);

        // If the error is a 422, it's a validation error and we'll want to show user modal w/ errors
        // We'll treat all other errors as a general error
        if (status === 422) {
          setUploadError(info as ErrorResponse);
        } else {
          setUploadError({
            error: GENERAL_UPLOAD_ERROR,
          });
        }
      }
    },
    [clearErrors, projectSubdomain, setValue],
  );

  const validateAndUploadLegacy = useCallback(
    async ({ file, url }: { file?: File; url?: string }) => {
      setIsUploadLoading(true);
      setUploadError(null);
      clearErrors();

      const uploadType = file ? 'upload' : 'hosted';

      // Set the type of upload in state for form error and success handling
      setType(uploadType);

      try {
        let body;

        if (file) {
          const formData = new FormData();
          formData.append('spec', file);
          body = formData;
        } else {
          body = JSON.stringify({
            url,
          });
        }

        const fetchOptions = {
          isFormData: !!file,
          method: 'POST',
          headers: {
            Authorization: authHeader,
            'x-readme-source': 'file',
            'x-readme-version': '1.0',
          },
          body,
        };

        // Validate OAS file first and generate preview
        const validationResponse = await fetcher<ValidationSuccessResponse>('/api/v1/api-validation', fetchOptions);

        setIsUploadLoading(false);

        if (validationResponse) {
          // Valid OAS specs will return spec so we can use it to preview in MockHub
          // Note: we set the ID to a random number here as we don't have an actual ID yet
          setValue('docsPreview', { ...validationResponse, _id: Math.random() });
        }

        // Proceed with uploading OAS file if there are no errors on validation
        try {
          // Mark API quickstart step as completed
          // This will happen on backend automatically when upload processes, but we want to force it here in case upload doesn't have time to finish
          if (projectSubdomain) {
            fetcher(`/api/projects/${projectSubdomain}`, {
              method: 'PUT',
              body: JSON.stringify({
                onboardingCompleted: { api: true },
              }),
            });
          }

          const response = await fetcher<SuccessfulUploadResponse>('/api/v1/api-specification', fetchOptions);

          // Set successful upload ID to form state so MockHub can fetch categories when ready
          setValue('successfulUploadId', response?._id);
        } catch (error) {
          // Do nothing, we'll silently fail any upload errors
        }
      } catch (error) {
        const { info, status } = error as HTTPError;

        setIsUploadLoading(false);

        // If the error is a 400, it's a validation error and we'll want to show user modal w/ errors
        // We'll treat all other errors as a general error
        if (status === 400) {
          setUploadError(info as ErrorResponse);
        } else {
          setUploadError({
            error: GENERAL_UPLOAD_ERROR,
          });
        }
      }
    },
    [authHeader, clearErrors, projectSubdomain, setValue],
  );

  const uploadFile = useCallback(
    (file: File) => {
      if (!file) return;

      if (isSuperHub) {
        validateAndUploadAPIv2({ file });
      } else {
        validateAndUploadLegacy({ file });
      }
    },
    [isSuperHub, validateAndUploadAPIv2, validateAndUploadLegacy],
  );

  const importURL = useCallback(async () => {
    if (!hostedURL) return;

    // Trigger form validation to ensure it's a URL via isUrl
    const isValid = await trigger('hostedURL');

    if (isValid) {
      // Pass provided URL through GitHub link parser
      const url = fixGitHubLinks(hostedURL);

      if (isSuperHub) {
        validateAndUploadAPIv2({ url });
      } else {
        validateAndUploadLegacy({ url });
      }
    }
  }, [hostedURL, isSuperHub, trigger, validateAndUploadAPIv2, validateAndUploadLegacy]);

  const openErrorModal = useCallback(() => {
    errorModalRef.current?.toggle(true);
  }, []);

  // If user has uploaded a valid OAS, we'll show them the success message and button to go to Dashboard
  // And we'll upload file in background
  const hasValidOAS = !!docsPreview && !docsPreview.isExample;

  let errorMessage = 'Whoops! We had a problem validating…';

  if (uploadError?.error === GENERAL_UPLOAD_ERROR) {
    errorMessage = 'Whoops! Something went wrong…';
  }

  const isInvalidSpecError = uploadError?.error !== GENERAL_UPLOAD_ERROR;
  const nextStepLabel = isSuperHub ? 'Docs' : 'Dashboard';

  return (
    <>
      <form className={bem('&')} onSubmit={onSubmit}>
        {!hasValidOAS && (
          <>
            <RHFGroup control={control} id="oasFile" name="oasFile">
              {({ field }) => (
                <>
                  <Flex align="center" className={bem('-upload-file')} gap="sm" justify="start" layout="col">
                    {/* The uploader is actually a hidden input that is triggered via a button as it's easier to style */}
                    <Button
                      fullWidth
                      kind="primary"
                      loading={type === 'upload' && isUploadLoading}
                      onClick={handleUploadClick}
                      outline={!!oasFile} // Added outline prop conditionally
                    >
                      {!(type === 'upload' && isUploadLoading) && (
                        <Icon color="white" name={oasFile ? 'file' : 'upload'} />
                      )}
                      {oasFile ? (
                        <span className={bem('-upload-file-name')}>{oasFile.name}</span>
                      ) : (
                        'Upload OpenAPI file'
                      )}
                    </Button>
                  </Flex>

                  <Input
                    {...field}
                    ref={uploadInputRef}
                    accept="application/json,.yaml"
                    data-testid="upload-input"
                    onChange={e => {
                      e.preventDefault();

                      if (!e.target.files?.length) return;

                      const file = e.target.files[0];

                      field.onChange(file);
                      uploadFile(file);
                    }}
                    style={{ display: 'none' }}
                    type="file"
                    value=""
                  />
                </>
              )}
            </RHFGroup>

            <div className={bem('-or-separator')}>
              <div className={bem('-or-separator-line')} /> OR <div className={bem('-or-separator-line')} />
            </div>

            <RHFGroup control={control} id="hostedURL" isUrl name="hostedURL">
              {({ field }) => (
                <InputGroup columnLayout="1fr auto auto" separators>
                  <Input {...field} autoFocus placeholder="https://" />
                  <Button
                    disabled={!hostedURL}
                    kind="primary"
                    loading={type === 'hosted' && isUploadLoading}
                    onClick={importURL}
                  >
                    {!(type === 'hosted' && isUploadLoading) && <Icon color="white" name="upload-cloud" />}
                    Import OpenAPI file
                  </Button>
                </InputGroup>
              )}
            </RHFGroup>
          </>
        )}

        {!!hasValidOAS && (
          <Flex align="center" className="FormGroup" gap="xs" justify="center" layout="col">
            <Flex align="center" gap="xs">
              <Icon color="green" name="check" size="sm" />
              That OpenAPI file looks great! Your docs are nearly ready to go.
            </Flex>
          </Flex>
        )}

        {!!uploadError && (
          <small className={bem('-form-group-error-with-link', 'FormGroup-error')} role="alert">
            {errorMessage}{' '}
            {isInvalidSpecError ? (
              <>
                {/* Show invalid spec errors in modal */}
                Please check errors{' '}
                <Button
                  className={bem('-error-modal-btn')}
                  data-testid="open-error-modal"
                  link
                  onClick={openErrorModal}
                >
                  here
                </Button>{' '}
                and correct anything before submitting.
              </>
            ) : (
              <>Please try to {type === 'upload' ? 'upload' : 'import'} OpenAPI file again.</>
            )}
          </small>
        )}

        {!!hasValidOAS && (
          <>
            <hr className={bem('-divider')} />
            <Button className={bem('-submit-btn')} fullWidth kind="primary" type="submit">
              Go to {nextStepLabel}
              <Icon color="white" name="arrow-right" />
            </Button>
          </>
        )}
        {!hasValidOAS && (
          <Button className={bem('-skip-btn')} kind="secondary" onClick={onSkip} size="sm" text>
            Skip to {nextStepLabel}
            <Icon name="arrow-right" />
          </Button>
        )}
      </form>

      <ErrorModal
        ref={errorModalRef}
        errorMessage={isSuperHub ? uploadError?.errors?.[0].message : uploadError?.message}
      />
    </>
  );
};

export default UploadOASForm;
