import loadable from '@loadable/component';
import React from 'react';

import RDMD, { TOC as RDMDToc } from './RDMD';

const RMDX = loadable(() => import('@ui/RDMD/RMDX'));
const RMDXToc = loadable(() => import('@ui/RDMD/RMDX'), {
  resolveComponent: components => components.TOC,
});

// eslint-disable-next-line react/prop-types
const MarkdownRenderer = ({ mdx, ...props } = {}) => (mdx ? <RMDX {...props} /> : <RDMD {...props} />);

// eslint-disable-next-line react/prop-types
export const TOC = ({ mdx, ...props } = {}) => (mdx ? <RMDXToc {...props} /> : <RDMDToc {...props} />);

export default MarkdownRenderer;
