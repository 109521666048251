import React, { useRef } from 'react';

import classy from '@core/utils/classy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import './style.scss';

export interface FormGroupProps {
  align?: 'baseline' | 'center' | 'end' | 'start' | 'stretch';
  children?: React.ReactNode;
  className?: string;
  /** Renders additional information below the input field. */
  description?: React.ReactNode | null;
  /** Renders error details below the input field but above `description`. */
  errorMessage?: React.ReactNode | null;
  /**
   * Whether to render `error` inline or as a tooltip when hovering over the
   * input field. Default is `inline`.
   */
  errorStyle?: 'inline' | 'tooltip';
  gap?: number | string;
  /**
   * Renders a tooltip next to the label containing this message. Useful when
   * you need to provide additional context about a particular field.
   */
  helpMessage?: React.ReactNode;
  htmlFor?: string;
  /** Renders a top-level label above the form input field. */
  label?: React.ReactNode;
  orientation?: 'horizontal' | 'vertical';
  size?: 'lg' | 'md' | 'sm';
  /** Renders a warning message below the input field. */
  warningMessage?: React.ReactNode | null;
}

/**
 * Layout proper grouping of labels, controls, optional help text, and form
 * validation messaging.
 */
const FormGroup = ({
  align,
  children,
  className,
  description,
  errorMessage,
  errorStyle = 'inline',
  helpMessage,
  htmlFor,
  label,
  orientation = 'vertical',
  size = 'md',
  warningMessage,
  ...attrs
}: FormGroupProps) => {
  const fieldContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classy(
        'FormGroup',
        className,
        orientation && `FormGroup_${orientation}`,
        size && `FormGroup_${size}`,
        errorMessage && 'FormGroup_error',
      )}
      {...attrs}
    >
      {!!label && (
        <label className="FormGroup-label" htmlFor={htmlFor}>
          {helpMessage ? (
            <Flex align="center" gap="xs">
              {label}
              {!!helpMessage && (
                <Tooltip arrow={false} content={helpMessage} delay={[400, 200]} offset={[0, 5]} placement="bottom">
                  <Icon color="color-text-minimum" name="help-circle" size={size} />
                </Tooltip>
              )}
            </Flex>
          ) : (
            label
          )}
        </label>
      )}
      <Flex align={align || 'stretch'} className="FormGroup-inputs" gap={0} layout="col">
        {!!children && (
          <div ref={fieldContainerRef} className="FormGroup-input">
            {children}
          </div>
        )}
        {!!(description || (errorMessage && errorStyle === 'inline') || warningMessage) && (
          <div className="FormGroup-messages">
            {!!errorMessage && errorStyle === 'inline' && (
              <small className="FormGroup-error" role="alert">
                {errorMessage}
              </small>
            )}
            {!!description && (
              <small className="FormGroup-description" role="note">
                {description}
              </small>
            )}
            {!!warningMessage && (
              <small className="FormGroup-warning" role="note">
                <Icon className="FormGroup-warning-icon" name="alert-triangle" />
                {warningMessage}
              </small>
            )}
          </div>
        )}
        {!!errorMessage && errorStyle === 'tooltip' && (
          <Tooltip
            arrow={false}
            className="FormGroup-error-tooltip"
            content={errorMessage}
            offset={[0, 5]}
            onCreate={tippy => tippy.show()}
            reference={fieldContainerRef}
          />
        )}
      </Flex>
    </div>
  );
};

export default React.memo(FormGroup);
