interface Position {
  ch: number;
  line: number;
}

type SelectionRange = [Position, Position];

export type SpreadLineNumbersResult = SelectionRange[] | undefined;

// Converting between human readable `1, 2-5` to codemirror selection syntax
const spreadLineNumbers = (ranges: number | string | undefined): SpreadLineNumbersResult => {
  return ranges
    ?.toString()
    ?.split(',')
    ?.map(range => {
      const [anchor, head] = range.split('-');

      const end = head || anchor ? parseInt(head || anchor, 10) : 0;
      const start = parseInt(anchor || '0', 10) - 1;

      return [
        { ch: 0, line: start },
        { ch: 0, line: end },
      ];
    });
};

export default spreadLineNumbers;
