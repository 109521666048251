import type { LegacyRDMDOpts, RMDXOpts } from '@readme/iso';

function isLegacy(options): options is LegacyRDMDOpts {
  return 'reusableContent' in options;
}

/**
 * Normalizes the rmdx options to the legacy format, where the custom blocks
 * representation is stored in the `reusableContent.tags` property.
 */
export function normalizeRmdxOptionsToLegacy(options: LegacyRDMDOpts | RMDXOpts): LegacyRDMDOpts {
  if (isLegacy(options)) {
    return options;
  }

  return {
    ...options,
    reusableContent: {
      tags: options.components,
    },
  };
}
