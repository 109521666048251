import type { FieldPath, FieldValues, RegisterOptions, UseControllerProps, UseControllerReturn } from 'react-hook-form';

import { SEMVER_REGEX } from '@readme/iso';
import produce from 'immer';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';

import type { FormGroupProps } from '@ui/FormGroup';
import FormGroup from '@ui/FormGroup';

type RHFControllerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Pick<
  UseControllerProps<TFieldValues, TName>,
  'control' | 'defaultValue' | 'name'
>;

type RHFValidationRules<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Pick<
  RegisterOptions<TFieldValues, TName>,
  'max' | 'maxLength' | 'min' | 'minLength' | 'required'
>;

type AllowedFormGroupProps = Pick<
  FormGroupProps,
  'description' | 'errorStyle' | 'helpMessage' | 'label' | 'size' | 'warningMessage'
>;

type ChildrenRenderer<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = (
  controllerProps: UseControllerReturn<TFieldValues, TName> & {
    field: Pick<RHFGroupProps<TFieldValues, TName>, 'id'>;
  },
) => React.ReactElement;

interface RHFGroupProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends RHFControllerProps<TFieldValues, TName>,
    RHFValidationRules<TFieldValues, TName>,
    AllowedFormGroupProps {
  /**
   * Renderer function with the same return values as the React Hook Form's
   * [Controller](https://react-hook-form.com/docs/usecontroller/controller)
   * element. Use this to render and register input fields, toggles, etc.
   */
  children?: ChildrenRenderer<TFieldValues, TName>;

  className?: string;

  /**
   * When supplied, associates this form group's label to the field  by
   * providing matching `htmlFor` and `id` values to the `<label>` and the
   * `field` prop that will be spread onto the input element.
   */
  id?: string;

  /**
   * Validate this field to be a semantic version like `1.0` or `1.0.2`.
   */
  isSemVer?: boolean;

  /**
   * Validate this field to have a valid `url` string.
   */
  isUrl?: boolean;
}

/**
 * Combines our `@ui/FormGroup` with a [React Hook Form](https://react-hook-form.com/)
 * to create a combined interface via props to declare a form field with id,
 * name, label and [validation rules](https://react-hook-form.com/get-started#Applyvalidation).
 *
 * Use this to create a form field with a label and input control that outputs
 * realtime error messages in a consistent way.
 *
 * @example
 * ```
 * import { RHFGroup } from '@ui/RHF';
 *
 * <RHFGroup control={control} id={uid('count')} label="Count" max={20} min={5} name="count" required>
 *   {({ field }) => <Input {...field} placeholder="Between 5-20" type="number" />}
 * </RHFGroup>
 * ```
 */
export default function RHFGroup<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  children,
  className,
  control,
  description,
  defaultValue,
  helpMessage,
  id,
  isSemVer,
  isUrl,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  required,
  warningMessage,
  ...attrs
}: RHFGroupProps<TFieldValues, TName>) {
  /**
   * Allow props to configure our Controller with RHF's validation rules. This
   * is where we define consistent validation messages for each rule.
   */
  const rules = useMemo(
    () => ({
      max:
        typeof max === 'number'
          ? {
              value: max,
              message: `Must be less than ${max}`,
            }
          : undefined,
      maxLength:
        typeof maxLength === 'number'
          ? {
              value: maxLength,
              message: `Must be less than ${maxLength} characters`,
            }
          : undefined,
      min:
        typeof min === 'number'
          ? {
              value: min,
              message: `Must be greater than ${min}`,
            }
          : undefined,
      minLength:
        typeof minLength === 'number'
          ? {
              value: minLength,
              message: `Must be at least ${minLength} characters`,
            }
          : undefined,
      required: required && 'Required',
      pattern: isSemVer
        ? {
            value: SEMVER_REGEX,
            message: 'Must be a semantic version',
          }
        : undefined,

      // Add additional custom validation rules here.
      validate: {
        ...(isUrl
          ? {
              url: (url: string): boolean | string => {
                const message = 'Requires a valid URL';
                if (!url) return true;
                try {
                  const test = new URL(url);
                  return !!test || message;
                } catch {
                  return message;
                }
              },
            }
          : undefined),
      },
    }),
    [isSemVer, isUrl, max, maxLength, min, minLength, required],
  );
  const controllerProps = useController({ control, defaultValue, name, rules });

  /**
   * Extends the existing `Controller.field` props with the `id` value so it can
   * get destructured into the child input control along with other field props.
   */
  const returnProps = useMemo(() => {
    if (!id) return controllerProps;
    return produce<
      typeof controllerProps & {
        field: Pick<RHFGroupProps<TFieldValues, TName>, 'id'>;
      }
    >(controllerProps, draft => {
      draft.field.id = id;
    });
  }, [controllerProps, id]);

  return (
    <FormGroup
      className={className}
      description={description}
      errorMessage={controllerProps.fieldState.error?.message}
      helpMessage={helpMessage}
      htmlFor={id}
      label={label}
      warningMessage={warningMessage}
      {...attrs}
    >
      {children?.(returnProps)}
    </FormGroup>
  );
}
