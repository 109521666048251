import loAt from 'lodash/at';
import React from 'react';

import classy from '@core/utils/classy';

import Spinner from '@ui/Spinner';

import './style.scss';

type Align = 'center' | 'char' | 'justify' | 'left' | 'right';

interface Props {
  align?: Align[];
  body:
    | (JSX.Element | number | string)[][]
    | (Record<string, React.ReactNode> | string)[]
    | Record<string, JSX.Element | React.ReactNode | boolean | string>[];
  /**
   * A list whose indexes coorespond to the body.
   * For example if body is a list of objects this would be a list of their ids, guids, or anything else that is definitely unique.
   * When using this prop it is strongly recommended to use an Array for `body` to garantee the ordering of the data.
   */
  bodyKeys?: (Record<string, React.ReactNode> | string)[];
  children?: React.ReactNode;
  className?: string;
  /** If provided, this text renders when the table has no data to show */
  emptyStateFallback?: JSX.Element | string;
  head?: ((number | string)[] | string)[][];
  isBordered?: boolean;
  isCondensed?: boolean;
  isDataGrid?: boolean;
  isLoading?: boolean;
  isSticky?: boolean;
  isStriped?: boolean;
  layout?: 'auto' | 'fixed' | 'inherit';
  onRowClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, key: string) => void;

  pick?: string[];
  rowHoverEnter?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, key: string) => void;
  rowHoverLeave?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, key: string) => void;
  spinner?: React.ReactNode;
  style?: Record<string, string>;
}

const TableHead = (key, cell, ...rest) => {
  const [, children, colSpan = 0, align = 'left', className = null] = Array.isArray(cell)
    ? [key, ...cell, ...rest]
    : [key, cell, 0, ...rest];

  return (
    <th key={key} align={align} className={className} colSpan={colSpan || null}>
      {children}
    </th>
  );
};

const TableCell = (key, cell, align: Align = 'left') => (
  <td key={key} align={align}>
    {cell}
  </td>
);

const Table = ({
  align = [],
  body,
  bodyKeys,
  children,
  className,
  emptyStateFallback,
  head,
  isBordered = true,
  isCondensed = false,
  isDataGrid = false,
  isLoading = false,
  isSticky = false,
  isStriped = false,
  layout = 'fixed',
  onRowClick,
  pick = [],
  rowHoverEnter,
  rowHoverLeave,
  spinner,
  style,
}: Props) => {
  const headEl = head && head.length && head.every(row => row.some(cell => cell[0])) && (
    <thead className={classy(isSticky && 'Table-thead_sticky')}>
      {head.map((row, i) => (
        <tr key={`th-${i}`}>{row.map((d, ii) => TableHead(ii, d, align[ii]))}</tr>
      ))}
    </thead>
  );

  return (
    <table
      className={classy(
        'Table',
        isBordered && 'Table_bordered',
        isCondensed && 'Table_condensed',
        isDataGrid && 'Table_dataGrid',
        isLoading && 'Table_loading',
        isStriped && 'Table_striped',
        className,
      )}
      style={{ tableLayout: layout, ...style }}
    >
      {children}

      {headEl}

      {!!isLoading && (
        <tbody className={'Table-spinner'}>
          <tr>
            <td>{spinner || <Spinner className={'Table-spinner-element'} />}</td>
          </tr>
        </tbody>
      )}

      <tbody>
        {body?.length ? (
          body
            .map((row, i) => {
              const key = ((bodyKeys && bodyKeys[i]) as string) || `tr-${i}`;
              const isObject = typeof row === 'object' && !Array.isArray(row);
              const handleObject = () =>
                (pick.length ? loAt(row, pick) : Object.keys(row)).map((k, ii) => {
                  if (k[0] === '_') return;

                  const d = row[k] || k;
                  // eslint-disable-next-line consistent-return
                  return TableCell(ii, d, align[ii]);
                });
              const handleArray = () => row.map((d, ii) => TableCell(ii, d, align[ii]));

              return [
                ...(row._isSeparate
                  ? [
                      <tr key={`${key}_separator`} className="Table-row_separator">
                        <td colSpan={Object.keys(body[0]).length} />
                      </tr>,
                    ]
                  : []),
                <tr
                  key={key}
                  className={classy(row._className || '', onRowClick && 'Table-row_hover')}
                  onClick={e => onRowClick && onRowClick(e, key)}
                  onMouseEnter={e => rowHoverEnter && rowHoverEnter(e, key)}
                  onMouseLeave={e => rowHoverLeave && rowHoverLeave(e, key)}
                >
                  {isObject ? handleObject() : handleArray()}
                </tr>,
              ];
            })
            .flat()
        ) : (
          <tr>{emptyStateFallback}</tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
