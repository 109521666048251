/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Owlmoji as OwlmojiMaps } from '@readme/markdown';
import fuzzysort from 'fuzzysort';
import React, { useCallback, useState } from 'react';

import classy from '@core/utils/classy';

import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Owlmoji from '@ui/Owlmoji';

import classes from './style.module.scss';

interface Emoji {
  aliases: string;
  name: string;
}

interface EmojiList {
  filteredEmojis: Emoji[];
  selected?: string;
}

interface EmojiPickerProps {
  onClick?: (newValue: string, event: React.MouseEvent) => void;
  /** Whether to include ReadMe specific Owlbert emojis */
  owlmoji?: boolean;
  /** The currently selected emoji */
  value?: string;
}

const emojis = OwlmojiMaps.owlmoji.map(emoji => {
  const [name, ...aliases] = emoji.names;
  return { name, aliases: aliases.join(', ').replace(/_/g, ' ') };
});

const emojisSansOwl = emojis.filter(({ name }) => OwlmojiMaps.kind(name) === 'gemoji');

const EmojiListUnmemoized = ({ filteredEmojis, selected }: EmojiList) => {
  return (
    <>
      {filteredEmojis.length > 0 ? (
        filteredEmojis.map(({ name }) => {
          const emojiClasses = `${classes['EmojiPicker-Button']} ${
            name === selected ? classes['EmojiPicker-Button_active'] : ''
          }`;

          return (
            <button key={name} className={emojiClasses} data-name={name} disabled={name === selected} type="button">
              <Owlmoji data-name={name}>{name}</Owlmoji>
            </button>
          );
        })
      ) : (
        <span className={classes['EmojiPicker-Empty']}>No results</span>
      )}
    </>
  );
};

const EmojiResults = React.memo<EmojiList>(EmojiListUnmemoized);

const EmojiPicker = ({ onClick, owlmoji = true, value }: EmojiPickerProps) => {
  const [selected, setSelected] = useState(value);
  const [filter, setFilter] = useState('');

  const filteredEmojis = filter
    ? fuzzysort
        .go<Emoji>(filter, owlmoji ? emojis : emojisSansOwl, {
          keys: ['name', 'aliases'],
        })
        .map(result => result.obj)
    : emojis;

  const handleClick = useCallback(
    event => {
      if (!('name' in event.target.dataset)) return;

      const newValue = event.target.dataset.name;
      setSelected(newValue);

      if (typeof onClick === 'function') onClick(newValue, event);
    },
    [onClick],
  );

  const buttonClasses = classy(
    classes['EmojiPicker-Button'],
    classes['EmojiPicker-Button_none'],
    selected === '' && classes['EmojiPicker-Button_active'],
  );

  return (
    <div className={classes.EmojiPicker} onClick={handleClick}>
      <Input
        className={classes['EmojiPicker-Search']}
        onChange={e => setFilter(e.target.value.trim())}
        placeholder="Search"
        prefix={<Icon name="search" />}
        size="sm"
        value={filter}
      />
      <div className={classes['EmojiPicker-Results']}>
        <button className={buttonClasses} data-name="" disabled={selected === ''} type="button">
          None
        </button>
        <EmojiResults filteredEmojis={filteredEmojis} selected={selected} />
      </div>
    </div>
  );
};

export default EmojiPicker;
