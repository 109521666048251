import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import useFullStory from '@core/hooks/useFullStory';

import OnboardingCard from './components/Card';
import CTA from './components/CTA';
import CreateProjectForm from './components/Forms/CreateProjectForm';
import SignupForm from './components/Forms/SignupForm';
import UploadOASForm from './components/Forms/UploadOASForm';
import OnboardingLayout from './components/Layout';
import MockHub from './components/MockHub';
import styles from './style.module.scss';

type SetupStepType = 'createProject' | 'signup' | 'uploadOAS';

const stepToEventType: Record<SetupStepType, AMPLITUDE_EVENT_PROPERTY> = {
  signup: AMPLITUDE_EVENT_PROPERTY.ONBOARDING_SIGNUP,
  createProject: AMPLITUDE_EVENT_PROPERTY.ONBOARDING_CREATE_PROJECT,
  uploadOAS: AMPLITUDE_EVENT_PROPERTY.ONBOARDING_UPLOAD_OAS,
};

interface Props {
  /**
   * Indicates if this is a direct new project creation navigation from Project List Page
   * When true, we'll skip the signup step and go straight to project creation
   * */
  isDirectNewProjectCreation?: boolean;
}

const Signup = ({ isDirectNewProjectCreation }: Props) => {
  const bem = useClassy(styles, 'Onboarding');
  const [currentStep, setCurrentStep] = useState<SetupStepType>(() => {
    // If we're on /signup/project/new, skip the signup step
    return isDirectNewProjectCreation ? 'createProject' : 'signup';
  });
  const [project, setProject] = useState<ProjectClientSide | null>(null);

  const { setUserID, track } = useAmplitude();
  const params = useParams();
  const location = useLocation();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  const { email: invitedEmail } = params as { email?: string };
  const hash = queryParams.get('hash');

  const isAdroll = queryParams.get('utm_source') === 'adroll' && queryParams.get('utm_medium') === 'adroll';
  const isGoogleCPC = queryParams.get('utm_source') === 'google' && queryParams.get('utm_medium') === 'cpc';
  const isAdTraffic = isAdroll || isGoogleCPC;
  const regex = /^(?!.*@(?:gmail\.com|yahoo\.com|icloud\.com)$).+$/;
  const isBusinessEmail = regex.test(project?.owner.email);

  // This is an invite signup if we have an email and hash
  const isInviteSignup = !!invitedEmail && !!hash;

  // Enable FullStory for signup flow
  useFullStory();

  // Track Amplitude view event for each step
  useEffect(() => {
    track(AMPLITUDE_EVENT.ONBOARDING_VIEW, {
      type: stepToEventType[currentStep],
    });
  }, [currentStep, track]);

  const navigateToDashboard = useCallback(() => {
    const isSuperHub = project?.flags?.superHub;
    const superhubOrDashPath = isSuperHub
      ? `/hub-go/${project?.subdomain}`
      : `/project/${project?.subdomain}/v1.0/quickstart`;

    // If we have a project, redirect to the dash/superhub
    const redirectTo = project ? superhubOrDashPath : '/';

    window.location.assign(redirectTo);
  }, [project]);

  const [inputFocused, setInputFocused] = useState(false);

  // Callbacks to handle input focus and blur
  const handleInputFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setInputFocused(false);
  }, []);

  const projectAPIKey = useMemo(() => {
    // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
    return project?.apiKey ?? '';
  }, [project]);

  const projectSubdomain = useMemo(() => {
    return project?.subdomain ?? '';
  }, [project]);

  const onProjectCreateSuccess = useCallback((proj: ProjectClientSide) => {
    setProject(proj);
    setCurrentStep('uploadOAS');
  }, []);

  const onUploadSkip = useCallback(() => {
    // Track onboarding as complete
    track(AMPLITUDE_EVENT.ONBOARDING_FINISH, {
      type: 'skip',
    });

    navigateToDashboard();
  }, [navigateToDashboard, track]);

  const onUploadSuccess = useCallback(
    ({ type, id }: { id?: string; type: string }) => {
      // Track OAS upload event with metadata of selected options
      // Note: the upload ID may be undefined if upload hasn't completed in background before user clicks Finish
      track(AMPLITUDE_EVENT.ONBOARDING_FINISH, {
        type: 'upload',
        upload: {
          type,
          id,
          uploadCompletedBeforeNavigation: !!id,
        },
      });
      setUploadSuccess(true);
      // On completion, navigate to dashboard
      navigateToDashboard();
    },
    [navigateToDashboard, track],
  );

  const onUserSignupSuccess = useCallback(
    data => {
      const email = data.email;

      /**
       * Set the user ID in Amplitude instance
       * Note: Signup success event is tracked server-side so we don't drop any events
       * from privacy-focused browser settings or ad blockers
       */
      setUserID(email);

      // GTM event on successful signup
      // @ts-ignore
      if (window.dataLayer) window.dataLayer.push({ event: 'signup' });

      // Invited signups don't create new projects and redirect to Dash home
      if (isInviteSignup) {
        window.location.assign('/');
        return;
      }

      // Proceed to project creation step
      setCurrentStep('createProject');
    },
    [isInviteSignup, setUserID],
  );

  const activeForm = useMemo(() => {
    switch (currentStep) {
      case 'createProject':
        return {
          form: (
            <CreateProjectForm
              isDirectNewProjectCreation={isDirectNewProjectCreation}
              onSuccess={onProjectCreateSuccess}
            />
          ),
        };
      case 'uploadOAS':
        return {
          subtitle: uploadSuccess ? '' : 'Add your OpenAPI file and we will build your docs!',
          form: (
            <UploadOASForm
              isSuperHub={!!project?.flags?.superHub}
              onSkip={onUploadSkip}
              onSuccess={onUploadSuccess}
              projectAPIKey={projectAPIKey}
              projectSubdomain={projectSubdomain}
            />
          ),
        };
      case 'signup':
      default:
        return {
          title: isInviteSignup
            ? "You've been invited to ReadMe"
            : isAdTraffic
              ? 'Get Started With ReadMe’s Interactive API Docs'
              : 'Welcome to ReadMe',
          subtitle: isAdTraffic
            ? 'Create interactive API docs by syncing your OAS file, onboard developers faster, and customize your docs for your brand.'
            : 'Jump right in to start building your docs 🚀',
          form: (
            <SignupForm
              hash={hash}
              invitedEmail={invitedEmail}
              isInviteSignup={isInviteSignup}
              onInputBlur={handleInputBlur}
              onInputFocus={handleInputFocus}
              onSuccess={onUserSignupSuccess}
            />
          ),
        };
    }
  }, [
    currentStep,
    handleInputBlur,
    handleInputFocus,
    hash,
    invitedEmail,
    isAdTraffic,
    isInviteSignup,
    isDirectNewProjectCreation,
    onProjectCreateSuccess,
    onUploadSkip,
    onUploadSuccess,
    onUserSignupSuccess,
    project?.flags?.superHub,
    projectAPIKey,
    projectSubdomain,
    uploadSuccess,
  ]);

  const { title, subtitle, form } = activeForm;

  const isSignupStep = currentStep === 'signup';
  const isUploadOASStep = currentStep === 'uploadOAS';

  return (
    <OnboardingLayout
      isDirectNewProjectCreation={isDirectNewProjectCreation}
      isSingleCard={isSignupStep}
      mockHub={<MockHub isUploadOASStep={isUploadOASStep} showNavModules={!isSignupStep} />}
      showBackgroundGrid
      showTermsAndConditions={isSignupStep}
    >
      <OnboardingCard
        isAdTraffic={isAdTraffic}
        isPasswordFocused={!!isSignupStep && inputFocused}
        subtitle={subtitle}
        title={title}
      >
        {form}
      </OnboardingCard>

      {!!isSignupStep && (
        <Link className={bem('-subtext')} to="/login">
          Already have an account? Log in
        </Link>
      )}

      {!!isUploadOASStep && (
        <div className={bem('-subtext-no-hover')}>
          No OpenAPI file? More API options live in the {project?.flags.superHub ? 'project settings' : 'dashboard'}.
        </div>
      )}
      {!!isUploadOASStep && !!isBusinessEmail && <CTA />}
    </OnboardingLayout>
  );
};

export default Signup;
