/* eslint-disable @typescript-eslint/no-use-before-define */
import type FieldTypes from './components/fields';
import type WidgetTypes from './components/widgets';
import type { $TSFixMe } from '@readme/iso';
import type { SchemaObject } from 'oas/types';
import type { OpenAPIV3 } from 'openapi-types';

import mergeAllOf from 'json-schema-merge-allof';
import jsonpointer from 'jsonpointer';
import React from 'react';

export const ADDITIONAL_PROPERTY_FLAG = '__additional_property';

const widgetMap = {
  boolean: {
    checkbox: 'CheckboxWidget',
    radio: 'RadioWidget',
    select: 'SelectWidget',
    hidden: 'HiddenWidget',
  },
  string: {
    text: 'TextWidget',
    password: 'PasswordWidget',
    email: 'EmailWidget',
    hostname: 'TextWidget',
    ipv4: 'TextWidget',
    ipv6: 'TextWidget',
    uri: 'URLWidget',
    'data-url': 'FileWidget',
    radio: 'RadioWidget',
    select: 'SelectWidget',
    textarea: 'TextareaWidget',
    hidden: 'HiddenWidget',
    color: 'ColorWidget',
    file: 'FileWidget',
  },
  number: {
    text: 'TextWidget',
    select: 'SelectWidget',
    updown: 'UpDownWidget',
    range: 'RangeWidget',
    radio: 'RadioWidget',
    hidden: 'HiddenWidget',
  },
  integer: {
    text: 'TextWidget',
    select: 'SelectWidget',
    updown: 'UpDownWidget',
    range: 'RangeWidget',
    radio: 'RadioWidget',
    hidden: 'HiddenWidget',
  },
  array: {
    select: 'SelectWidget',
    files: 'FileWidget',
    hidden: 'HiddenWidget',
  },
};

export function shouldAlwaysUseDefaults(formContext = {}) {
  return 'alwaysUseDefaults' in formContext ? formContext.alwaysUseDefaults : false;
}

function hasRef(schema: unknown): schema is { $ref: string } {
  return typeof schema === 'object' && !!schema && '$ref' in schema;
}

export function getDefaultRegistry() {
  return {
    // we need `require` statements here because these modules must be loaded synchronously
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    fields: require('./components/fields').default as typeof FieldTypes,
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    widgets: require('./components/widgets').default as typeof WidgetTypes,
    definitions: {},
    rootSchema: {},
    formContext: {},
  };
}

export function isPolymorphic(schema) {
  return !!(schema.anyOf || schema.oneOf);
}

/**
 * Determine if a schema `type` is, or contains, a specific discriminator.
 *
 * @param {SchemaObject} schema
 * @param {'array' | 'object' | 'string' | 'number' | 'integer' | 'null'} discriminator
 * @returns {boolean}
 */
export function hasSchemaType(schema, discriminator) {
  if (Array.isArray(schema.type)) {
    return schema.type.includes(discriminator);
  }

  return schema.type === discriminator;
}

/* Gets the type of a given schema. */
export function getSchemaType(schema: SchemaObject) {
  const { type } = schema;

  if (!type && 'const' in schema) {
    return guessType(schema.const);
  }

  if (!type && schema.enum) {
    return 'string';
  }

  if (!type && (schema.properties || schema.additionalProperties)) {
    return 'object';
  }

  // If we have an array type let's pick the first non-null item it's got so we can render out
  // a field widget for that.
  if (Array.isArray(type)) {
    return type.find(t => t !== 'null');
  }

  return type;
}

// detects whether a schema references itself recursively
export function isCyclic(schema: SchemaObject, rootSchema?: SchemaObject, options = { array: true }) {
  const traversed = new Set();

  /**
   * Helper fn to recurse into children
   * @param children The target array
   * @return returns whether there was a cyclic event in the children
   */
  const checkChildren = (children: SchemaObject[]) => {
    return children.reduce<boolean>((acc, child) => {
      const result = check(child) || acc;
      traversed.delete(child);
      return result;
    }, false);
  };

  const check = (schemaToCheck: SchemaObject | null): boolean => {
    // handling of malformed data
    if (typeof schemaToCheck === 'undefined' || typeof schemaToCheck !== 'object' || schemaToCheck === null) {
      return false;
    }

    if (traversed.has(schemaToCheck)) {
      return true;
    }

    traversed.add(schemaToCheck);

    if (hasRef(schemaToCheck)) {
      // If the ref is null, this part of the schema is not cyclical
      if (!schemaToCheck.$ref) {
        return false;
      }

      const refSchema = findSchemaDefinition(schemaToCheck.$ref, rootSchema);
      const result = check(refSchema);
      traversed.delete(schemaToCheck); // we need to delete here because siblings can refer to the same definition
      return result;
    }

    if ('anyOf' in schemaToCheck || 'allOf' in schemaToCheck || 'oneOf' in schemaToCheck) {
      const result = checkChildren(
        Object.values((schemaToCheck.anyOf as SchemaObject) || schemaToCheck.allOf || schemaToCheck.oneOf),
      );
      traversed.delete(schemaToCheck);
      return result;
    }

    switch (schemaToCheck.type) {
      case 'object': {
        if (!schemaToCheck.properties) {
          traversed.delete(schemaToCheck);
          return false;
        }
        const result = checkChildren(Object.values(schemaToCheck.properties));
        traversed.delete(schemaToCheck);
        return result;
      }
      case 'array': {
        if (!options.array) {
          return false; // we don't want to be proactive about checking for definitions in arrays
        }
        if (Array.isArray(schemaToCheck.items)) {
          const result = checkChildren(schemaToCheck.items as SchemaObject[]);
          traversed.delete(schemaToCheck);
          return result;
        }

        const result = check((schemaToCheck as OpenAPIV3.ArraySchemaObject).items);
        traversed.delete(schemaToCheck);
        return result;
      }
    }

    // edge case to handle the scan at the root
    if (schemaToCheck === rootSchema && 'definitions' in schemaToCheck && schemaToCheck.definitions) {
      return checkChildren(Object.values(schemaToCheck.definitions));
    }
    traversed.delete(schemaToCheck);
    return false;
  };

  return check(schema);
}

export function getWidget(schema, widget, registeredWidgets = {}) {
  const type = getSchemaType(schema);

  if (!type) {
    throw new TypeError('Undefined type');
  }

  function mergeOptions(Widget) {
    // cache return value as property of widget for proper react reconciliation
    if (!Widget.MergedWidget) {
      const defaultOptions = (Widget.defaultProps && Widget.defaultProps.options) || {};
      // eslint-disable-next-line react/display-name
      Widget.MergedWidget = ({ options = {}, ...props }) => (
        <Widget options={{ ...defaultOptions, ...options }} {...props} />
      );
    }
    return Widget.MergedWidget;
  }

  if (typeof widget === 'function') {
    return mergeOptions(widget);
  }

  if (typeof widget !== 'string') {
    throw new TypeError(`Unsupported widget definition: ${typeof widget}`);
  }

  if (Object.prototype.hasOwnProperty.call(registeredWidgets, widget)) {
    const registeredWidget = registeredWidgets[widget];
    return getWidget(schema, registeredWidget, registeredWidgets);
  }

  if (!Object.prototype.hasOwnProperty.call(widgetMap, type)) {
    throw new Error(`No widget for type "${type}"`);
  }

  if (Object.prototype.hasOwnProperty.call(widgetMap[type], widget)) {
    const registeredWidget = registeredWidgets[widgetMap[type][widget]];
    return getWidget(schema, registeredWidget, registeredWidgets);
  }

  throw new Error(`No widget "${widget}" for type "${type}"`);
}

export function hasWidget(schema, widget, registeredWidgets = {}) {
  try {
    getWidget(schema, widget, registeredWidgets);
    return true;
  } catch (e) {
    if (e.message && (e.message.startsWith('No widget') || e.message.startsWith('Unsupported widget'))) {
      return false;
    }
    throw e;
  }
}

function computeDefaults(
  _schema,
  parentDefaults,
  rootSchema: SchemaObject,
  rawFormData = {},
  opts: {
    alwaysUseDefaults?: boolean;
    key?: string;
    minItemsExist?: boolean;
    parentRequired?: boolean;
    requiredParams?: string[];
  } = {
    alwaysUseDefaults: false,
    requiredParams: [],
    key: '',
    minItemsExist: false,
    parentRequired: false,
  },
) {
  const { alwaysUseDefaults, requiredParams, key, minItemsExist, parentRequired } = {
    alwaysUseDefaults: false,
    requiredParams: [],
    key: '',
    ...opts,
  };

  // `requiredParentParams` is used in computing defaults ONLY for
  // child properties that have a required parent param
  // (e.g., the parent param is an object or array that is marked as required by its parent)
  const requiredParentParams = rootSchema?.required;

  let schema = isObject(_schema) ? _schema : {};
  const formData = isObject(rawFormData) ? rawFormData : {};

  // Compute the defaults recursively: give highest priority to deepest nodes.
  let defaults = parentDefaults;

  // Read-only schemas shouldn't be factored into defaults as they aren't visible in the form.
  if ('readOnly' in schema && !!schema.readOnly) {
    return defaults;
  }

  if (isObject(defaults) && isObject(schema.default)) {
    // For object defaults, only override parent defaults that are defined in
    // schema.default.
    defaults = mergeObjects(defaults, schema.default);
  } else if ('default' in schema) {
    if (alwaysUseDefaults || (!alwaysUseDefaults && key && requiredParams?.includes(key))) {
      // Use schema defaults for this node.
      defaults = schema.default;
    }
  } else if (hasRef(schema)) {
    // Use referenced schema defaults for this node.
    const refSchema = findSchemaDefinition(schema.$ref, rootSchema);

    if (isCyclic(schema, rootSchema)) {
      return undefined;
    }

    // If there is a $ref, there won't be a `schema.required` so we need to pass in any
    // `requiredParams` that is at the parent object.
    return computeDefaults(refSchema, defaults, rootSchema, formData, {
      alwaysUseDefaults,
      requiredParams,
      key,
    });
  } else if (isFixedItems(schema)) {
    defaults = schema.items.map((itemSchema, idx) =>
      computeDefaults(
        itemSchema,
        Array.isArray(parentDefaults) ? parentDefaults[idx] : undefined,
        rootSchema,
        formData,
        {
          alwaysUseDefaults,
          requiredParams: Array.isArray(schema.required) ? schema.required : [],
          key,
        },
      ),
    );
  } else if ('oneOf' in schema && !Object.keys(formData)?.length) {
    schema = schema.oneOf[0];
  } else if ('anyOf' in schema) {
    schema = schema.anyOf[0];
  }

  if ('enum' in schema && Array.isArray(schema.enum) && !defaults) {
    // If this enum has only a single item, or is a required part of the schema and doesn't
    // have a default, then we should pick the first item as a default.
    if ((requiredParams && key && requiredParams.includes(key)) || minItemsExist) {
      defaults = schema.enum[0];
    }
  }

  // Not defaults defined for this node, fallback to generic typed ones.
  if (typeof defaults === 'undefined') {
    if (alwaysUseDefaults) {
      defaults = schema.default;
    } else if (requiredParams && key && requiredParams.includes(key)) {
      defaults = schema.default;
    } else if (key === '' && 'default' in schema) {
      // If our `key` is an empty string but we **do** have defaults present then we're in a schema
      // they are unable to set a `required` array (eg `{additionalProperties: { type: string }}`)
      // so we should just return the defaults that they've got defined.
      if (hasSchemaType(schema, 'string') || hasSchemaType(schema, 'boolean') || hasSchemaType(schema, 'number')) {
        defaults = schema.default;
      }
    }
  }

  switch (getSchemaType(schema)) {
    // We need to recur for object schema inner default values.
    case 'object':
      return Object.keys(schema.properties || {}).reduce((acc, currentProperty) => {
        // Compute the defaults for this node
        let currentPropertyRequired = parentRequired;

        if (currentPropertyRequired === undefined) {
          // checks the parent schema's `required` array to determine
          // if the current property is required
          currentPropertyRequired =
            Array.isArray(requiredParentParams) && requiredParentParams.includes(currentProperty);
        }

        if (
          // if the parent schema doesn't have a default specified
          // and either the current property is marked as required
          // or the current reference section always surfaces defaults
          (rootSchema && !('default' in rootSchema) && (currentPropertyRequired || alwaysUseDefaults)) ||
          // if the rootSchema is an empty object,
          // the current property is most likely a top-level primitive
          !Object.keys(rootSchema).length ||
          // if the rootSchema has a default that is of `object` type, it is most likely
          // a top level object example, so let's use that
          isObject(rootSchema?.default) ||
          // if polymorphism exists, we can just calculate the default for the schema
          'anyOf' in rootSchema ||
          'oneOf' in rootSchema
        ) {
          const computedDefault = computeDefaults(
            schema.properties[currentProperty],
            (defaults || {})[currentProperty],
            rootSchema,
            (formData || {})[currentProperty],
            {
              alwaysUseDefaults,
              requiredParams: Array.isArray(schema.required) ? schema.required : [],
              key: currentProperty,
              minItemsExist,
              parentRequired: currentPropertyRequired,
            },
          );

          if (computedDefault !== undefined) {
            acc[currentProperty] = computedDefault;
          }
        }
        return acc;
      }, {});

    case 'array':
      // Inject defaults into existing array defaults
      if (Array.isArray(defaults)) {
        defaults = defaults.map((item, idx) => {
          return computeDefaults(
            schema.items[idx] || schema.additionalItems || {},
            item,
            rootSchema,
            {},
            {
              alwaysUseDefaults,
              requiredParams: Array.isArray(schema.required) ? schema.required : [],
              key,
              parentRequired: !!schema.minItems,
            },
          );
        });
      }

      // Deeply inject defaults into already existing form data
      if (Array.isArray(rawFormData)) {
        defaults = rawFormData.map((item, idx) => {
          return computeDefaults(schema.items, (defaults || {})[idx], rootSchema, item, {
            alwaysUseDefaults,
            requiredParams: Array.isArray(schema.required) ? schema.required : [],
            key,
            parentRequired: !!schema.minItems,
          });
        });
      }

      if (schema.minItems) {
        if (!isMultiSelect(schema, rootSchema)) {
          const defaultsLength = defaults ? defaults.length : 0;
          if (schema.minItems > defaultsLength) {
            const defaultEntries = defaults || [];
            // populate the array with the defaults
            const fillerSchema = Array.isArray(schema.items) ? schema.additionalItems : schema.items;

            // Primitive schemas within an `array` `items` schema will always be using the parental
            // `required` definition instead of the one inside itself because primitive schemas
            // can't declare themselves as required.
            let fillerRequiredParams: string[] = [];
            if (
              hasSchemaType(fillerSchema, 'string') ||
              hasSchemaType(fillerSchema, 'boolean') ||
              hasSchemaType(fillerSchema, 'number')
            ) {
              fillerRequiredParams = Array.isArray(requiredParams) ? requiredParams : [];
            } else {
              fillerRequiredParams = Array.isArray(fillerSchema.required) ? fillerSchema.required : [];
            }

            const fillerEntries = new Array(schema.minItems - defaultsLength).fill(
              computeDefaults(
                fillerSchema,
                fillerSchema.defaults,
                rootSchema,
                {},
                {
                  alwaysUseDefaults,
                  requiredParams: fillerRequiredParams,
                  key,
                  minItemsExist: !!schema.minItems,
                  parentRequired: !!schema.minItems,
                },
              ),
            );

            // then fill up the rest with either the item default or empty, up to minItems
            return defaultEntries.concat(fillerEntries);
          }
        } else {
          return defaults || [];
        }
      }
      break;

    case 'boolean':
      if ((requiredParams && key && requiredParams.includes(key) && defaults === undefined) || minItemsExist) {
        defaults = true;
      }
  }
  return defaults;
}

export function getDefaultFormState(
  _schema: SchemaObject,
  opts: { alwaysUseDefaults?: boolean; formData?: $TSFixMe; rootSchema?: SchemaObject } = {
    formData: undefined,
    rootSchema: {},
    alwaysUseDefaults: false,
  },
) {
  const { formData, rootSchema, alwaysUseDefaults } = {
    formData: undefined,
    rootSchema: {},
    alwaysUseDefaults: false,
    ...opts,
  };

  if (!isObject(_schema)) {
    throw new Error(`Invalid schema: ${_schema}`);
  }

  const schema = retrieveSchema(_schema, rootSchema, formData);
  if (isObject(schema) && 'readOnly' in schema && !!schema.readOnly) {
    return formData || undefined;
  }

  const defaults = computeDefaults(schema, _schema.default, rootSchema, {}, { alwaysUseDefaults });
  if (typeof formData === 'undefined') {
    // No form data? Use schema defaults.
    return defaults;
  }
  if (isObject(formData) || Array.isArray(formData)) {
    return mergeDefaultsWithFormData(defaults, formData);
  }
  if (formData === 0 || formData === false || formData === '') {
    return formData;
  }
  return formData || defaults;
}

/**
 * When merging defaults and form data, we want to merge in this specific way:
 * - objects are deeply merged
 * - arrays are merged in such a way that:
 *   - when the array is set in form data, only array entries set in form data
 *     are deeply merged; additional entries from the defaults are ignored
 *   - when the array is not set in form data, the default is copied over
 * - scalars are overwritten/set by form data
 */
export function mergeDefaultsWithFormData(defaults, formData) {
  if (Array.isArray(formData)) {
    if (!Array.isArray(defaults)) {
      // eslint-disable-next-line no-param-reassign
      defaults = [];
    }
    return formData.map((value, idx) => {
      if (defaults[idx]) {
        return mergeDefaultsWithFormData(defaults[idx], value);
      }
      return value;
    });
  } else if (isObject(formData)) {
    const acc = { ...defaults }; // Prevent mutation of source object.
    return Object.keys(formData).reduce((acc2, key) => {
      acc2[key] = mergeDefaultsWithFormData(defaults ? defaults[key] : {}, formData[key]);
      return acc2;
    }, acc);
  }

  return formData;
}

export function getUiOptions(uiSchema) {
  // get all passed options from ui:widget, ui:options, and ui:<optionName>
  return Object.keys(uiSchema)
    .filter(key => key.indexOf('ui:') === 0)
    .reduce((options, key) => {
      const value = uiSchema[key];

      if (key === 'ui:widget' && isObject(value)) {
        // console.warn('Setting options via ui:widget object is deprecated, use ui:options instead');
        return {
          ...options,
          ...value.options,
          widget: value.component,
        };
      }
      if (key === 'ui:options' && isObject(value)) {
        return { ...options, ...value };
      }
      return { ...options, [key.substring(3)]: value };
    }, {});
}

export function isObject(thing: unknown) {
  if (typeof File !== 'undefined' && thing instanceof File) {
    return false;
  }
  return typeof thing === 'object' && thing !== null && !Array.isArray(thing);
}

export function mergeObjects(obj1, obj2, concatArrays = false) {
  // Recursively merge deeply nested objects.
  const acc = { ...obj1 }; // Prevent mutation of source object.
  return Object.keys(obj2).reduce((acc2, key) => {
    const left = obj1 ? obj1[key] : {};
    const right = obj2[key];
    if (obj1 && Object.prototype.hasOwnProperty.call(obj1, key) && isObject(right)) {
      acc2[key] = mergeObjects(left, right, concatArrays);
    } else if (concatArrays && Array.isArray(left) && Array.isArray(right)) {
      acc2[key] = left.concat(right);
    } else {
      acc2[key] = right;
    }
    return acc2;
  }, acc);
}

export function asNumber(value) {
  if (value === '') {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  if (/\.$/.test(value)) {
    // "3." can't really be considered a number even if it parses in js. The
    // user is most likely entering a float.
    return value;
  }
  if (/\.0$/.test(value)) {
    // we need to return this as a string here, to allow for input like 3.07
    return value;
  }

  if (/\.\d*0$/.test(value)) {
    // It's a number, that's cool - but we need it as a string so it doesn't screw
    // with the user when entering dollar amounts or other values (such as those with
    // specific precision or number of significant digits)
    return value;
  }

  // If this value is larger than what the browser can handle without getting into BigInt territory
  // let's just return it outright. By doing this we'll actually be returning a string here (since
  // the incoming value is a string), but the browser will not let us transform this into a number.
  //
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
  // @ts-expect-error see this thread for background: https://github.com/readmeio/readme/pull/6108/files#r794701246
  if (`${value}` > Number.MAX_SAFE_INTEGER) {
    return value;
  }

  const n = Number(value);
  const valid = typeof n === 'number' && !Number.isNaN(n);

  return valid ? n : value;
}

/**
 * This function checks if the given schema matches a single
 * constant value.
 */
export function isConstant(schema: SchemaObject) {
  return (
    (Array.isArray(schema.enum) && schema.enum.length === 1) || Object.prototype.hasOwnProperty.call(schema, 'const')
  );
}

export function toConstant(schema: SchemaObject) {
  if (Array.isArray(schema.enum) && schema.enum.length === 1) {
    return schema.enum[0];
  } else if ('const' in schema && Object.prototype.hasOwnProperty.call(schema, 'const')) {
    return schema.const;
  }

  throw new Error('schema cannot be inferred as a constant');
}

export function isSelect(_schema: SchemaObject, rootSchema: SchemaObject = {}) {
  const schema = retrieveSchema(_schema, rootSchema);
  const altSchemas = schema.oneOf || schema.anyOf;
  if (Array.isArray(schema.enum)) {
    return true;
  } else if (Array.isArray(altSchemas)) {
    return altSchemas.every(altSchema => isConstant(altSchema));
  }
  return false;
}

export function isMultiSelect(schema, rootSchema: SchemaObject = {}) {
  if (!schema.uniqueItems || !('items' in schema) || !schema.items) {
    return false;
  }
  return isSelect(schema.items, rootSchema);
}

export function isFilesArray(schema, uiSchema, rootSchema: SchemaObject = {}) {
  if (uiSchema['ui:widget'] === 'files') {
    return true;
  } else if (schema.items) {
    const itemsSchema = retrieveSchema(schema.items, rootSchema);
    return hasSchemaType(itemsSchema, 'string') && itemsSchema.format === 'data-url';
  }
  return false;
}

export function isFixedItems(schema: SchemaObject) {
  return (
    'items' in schema &&
    Array.isArray(schema.items) &&
    schema.items.length > 0 &&
    schema.items.every(item => isObject(item))
  );
}

export function allowAdditionalItems(schema) {
  if (schema.additionalItems === true) {
    // eslint-disable-next-line no-console
    console.warn('additionalItems=true is currently not supported');
  }
  return isObject(schema.additionalItems);
}

export function optionsList(schema) {
  if (schema.enum) {
    return schema.enum.map((value, i) => {
      const label = (schema.enumNames && schema.enumNames[i]) || String(value);
      return { label, value };
    });
  }

  const altSchemas = schema.oneOf || schema.anyOf;
  return altSchemas.map(altSchema => {
    const value = toConstant(altSchema);
    const label = altSchema.title || String(value);
    return { label, value };
  });
}

export function findSchemaDefinition($ref, rootSchema = {}) {
  if ($ref === null) {
    throw new Error('Reference should not be null');
  }

  const origRef = $ref;
  if ($ref.startsWith('#')) {
    // Decode URI fragment representation.
    // eslint-disable-next-line no-param-reassign
    $ref = decodeURIComponent($ref.substring(1));
  } else {
    throw new Error(`Could not find a definition for ${origRef}.`);
  }
  const current = jsonpointer.get(rootSchema, $ref);
  if (current === undefined) {
    throw new Error(`Could not find a definition for ${origRef}.`);
  }
  if (hasRef(current)) {
    return findSchemaDefinition(current.$ref, rootSchema);
  }
  return current;
}

// In the case where we have to implicitly create a schema, it is useful to know what type to use
//  based on the data we are defining
export function guessType(value?: unknown) {
  if (Array.isArray(value)) {
    return 'array';
  } else if (typeof value === 'string') {
    return 'string';
  } else if (value == null) {
    return 'null';
  } else if (typeof value === 'boolean') {
    return 'boolean';
  } else if (typeof value === 'number' && !Number.isNaN(value)) {
    return 'number';
  } else if (typeof value === 'object') {
    return 'object';
  }
  // Default to string if we can't figure it out
  return 'string';
}

// This function will create new "properties" items for each key in our formData
export function stubExistingAdditionalProperties(schema, rootSchema = {}, formData = {}) {
  // Clone the schema so we don't ruin the consumer's original
  // eslint-disable-next-line no-param-reassign
  schema = {
    ...schema,
    properties: { ...schema.properties },
  };

  Object.keys(formData || {}).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(schema.properties, key)) {
      // No need to stub, our schema already has the property
      return;
    }

    let additionalProperties;
    if (hasRef(schema.additionalProperties)) {
      additionalProperties = retrieveSchema({ $ref: schema.additionalProperties.$ref }, rootSchema, formData);
    } else if (
      typeof schema.additionalProperties === 'object' &&
      !!schema.additionalProperties &&
      'type' in schema.additionalProperties
    ) {
      additionalProperties = { ...schema.additionalProperties };
    } else {
      additionalProperties = { type: guessType(formData[key]) };
    }

    // The type of our new key should match the additionalProperties value;
    schema.properties[key] = additionalProperties;
    // Set our additional property flag so we know it was dynamically added
    schema.properties[key][ADDITIONAL_PROPERTY_FLAG] = true;
  });

  return schema;
}

export function resolveSchema(schema: SchemaObject, rootSchema: SchemaObject = {}, formData = {}) {
  if (hasRef(schema)) {
    return resolveReference(schema, rootSchema, formData);
  } else if (Object.prototype.hasOwnProperty.call(schema, 'allOf')) {
    return {
      ...schema,
      allOf: (schema.allOf || []).map(allOfSubschema => retrieveSchema(allOfSubschema, rootSchema, formData)),
    };
  }

  // No $ref or allOf attribute found, returning the original schema.
  return schema;
}

function resolveReference(schema: SchemaObject, rootSchema: SchemaObject, formData) {
  if (!hasRef(schema)) {
    // this should never get hit, but this is a type guard just in case.
    throw new Error(`Attempting to resolve $ref that does not exist for ${JSON.stringify(schema)}`);
  }
  // Retrieve the referenced schema definition.
  const $refSchema = findSchemaDefinition(schema.$ref, rootSchema);
  // Drop the $ref property of the source schema.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { $ref, ...localSchema } = schema;
  // Update referenced schema definition with local schema properties.
  return retrieveSchema({ ...$refSchema, ...localSchema }, rootSchema, formData);
}

export function retrieveSchema(schema: SchemaObject, rootSchema: SchemaObject = {}, formData = {}) {
  if (!isObject(schema)) {
    return {};
  }
  let resolvedSchema = resolveSchema(schema, rootSchema, formData);
  if ('allOf' in schema) {
    try {
      resolvedSchema = mergeAllOf(
        {
          ...resolvedSchema,
          allOf: resolvedSchema.allOf,
        },
        {
          resolvers: {
            // JSON Schema's support for examples is relegated to the `examples` property. If we have this instead, let
            // it be!
            example: obj => obj[0],

            // JSON Schema has no support for `format` on anything other than `string`, but since OpenAPI has it on
            // `integer` and `number`, we need to add a custom resolver here so we can still merge schemas that may
            // have those!
            format: obj => obj[0],

            // Since JSON Schema obviously doesn't know about our vendor extension we need to tell
            // `json-schema-merge-allof` to essentially ignore and pass it along.
            'x-readme-ref-name': obj => obj[0],
          },
          ignoreAdditionalProperties: true,
        },
      );
    } catch (e) {
      // console.warn(`could not merge subschemas in allOf:\n${e}`);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { allOf, ...resolvedSchemaWithoutAllOf } = resolvedSchema;
      return resolvedSchemaWithoutAllOf;
    }
  }
  const hasAdditionalProperties =
    Object.prototype.hasOwnProperty.call(resolvedSchema, 'additionalProperties') &&
    resolvedSchema.additionalProperties !== false;
  if (hasAdditionalProperties) {
    return stubExistingAdditionalProperties(resolvedSchema, rootSchema, formData);
  }
  return resolvedSchema;
}

function isArguments(object) {
  return Object.prototype.toString.call(object) === '[object Arguments]';
}

export function deepEquals(a: unknown, b: unknown, ca: (typeof a)[] = [], cb: (typeof b)[] = []) {
  // Partially extracted from node-deeper and adapted to exclude comparison
  // checks for functions.
  // https://github.com/othiym23/node-deeper
  if (a === b) {
    return true;
  } else if (typeof a === 'function' || typeof b === 'function') {
    // Assume all functions are equivalent
    // see https://github.com/mozilla-services/react-jsonschema-form/issues/255
    return true;
  } else if (typeof a !== 'object' || typeof b !== 'object') {
    return false;
  } else if (a === null || b === null) {
    return false;
  } else if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  } else if (a instanceof RegExp && b instanceof RegExp) {
    return (
      a.source === b.source &&
      a.global === b.global &&
      a.multiline === b.multiline &&
      a.lastIndex === b.lastIndex &&
      a.ignoreCase === b.ignoreCase
    );
  } else if (isArguments(a) || isArguments(b)) {
    if (!(isArguments(a) && isArguments(b))) {
      return false;
    }
    const slice = Array.prototype.slice;
    return deepEquals(slice.call(a), slice.call(b), ca, cb);
  }

  // Because some folks decide to have parameters in their API named `constructor` we need to make
  // sure that this `constructor` property is a JS constructor and not from a JSON Schema schema
  // object.
  if (
    (a.constructor && !Object.prototype.hasOwnProperty.call(a, 'constructor')) !==
    (b.constructor && !Object.prototype.hasOwnProperty.call(b, 'constructor'))
  ) {
    return false;
  }

  const ka = Object.keys(a);
  const kb = Object.keys(b);
  // don't bother with stack acrobatics if there's nothing there
  if (ka.length === 0 && kb.length === 0) {
    return true;
  }
  if (ka.length !== kb.length) {
    return false;
  }

  let cal = ca.length;
  while (cal--) {
    if (ca[cal] === a) {
      return cb[cal] === b;
    }
  }
  ca.push(a);
  cb.push(b);

  ka.sort();
  kb.sort();
  for (let j = ka.length - 1; j >= 0; j--) {
    if (ka[j] !== kb[j]) {
      return false;
    }
  }

  let key;
  for (let k = ka.length - 1; k >= 0; k--) {
    key = ka[k];
    if (!deepEquals(a[key], b[key], ca, cb)) {
      return false;
    }
  }

  ca.pop();
  cb.pop();

  return true;
}

export function shouldRender(comp, nextProps, nextState) {
  const { props, state } = comp;
  return !deepEquals(props, nextProps) || !deepEquals(state, nextState);
}

export function toIdSchema(
  schema,
  id?: string | null,
  rootSchema?: SchemaObject,
  formData: $TSFixMe = {},
  idPrefix = 'root',
) {
  const idSchema = {
    $id: id || idPrefix,
  };

  if (isCyclic(schema, rootSchema)) {
    return idSchema;
  }

  if (hasRef(schema) || 'allOf' in schema) {
    const _schema = retrieveSchema(schema, rootSchema, formData);
    return toIdSchema(_schema, id, rootSchema, formData, idPrefix);
  }
  if ('items' in schema && !schema.items.$ref) {
    return toIdSchema(schema.items, id, rootSchema, formData, idPrefix);
  }
  if (schema.type !== 'object') {
    return idSchema;
  }
  Object.keys(schema.properties || {}).forEach(name => {
    const field = schema.properties[name];
    const fieldId = `${idSchema.$id}_${name}`;
    idSchema[name] = toIdSchema(
      isObject(field) ? field : {},
      fieldId,
      rootSchema,
      // It's possible that formData is not an object -- this can happen if an
      // array item has just been added, but not populated with data yet
      (formData || {})[name],
      idPrefix,
    );
  });
  return idSchema;
}

export function dataURItoBlob(dataURI) {
  // Split metadata from data
  const splitted = dataURI.split(',');
  // Split params
  const params = splitted[0].split(';');
  // Get mime-type from params
  const type = params[0].replace('data:', '');
  // Filter the name property from params
  const properties = params.filter(param => {
    return param.split('=')[0] === 'name';
  });
  // Look for the name and use unknown if no name property.
  let name;
  if (properties.length !== 1) {
    name = 'unknown';
  } else {
    // Because we filtered out the other property,
    // we only have the name case here.
    name = properties[0].split('=')[1];
  }

  // Built the Uint8Array Blob parameter from the base64 string.
  const binary = atob(splitted[1]);
  const array: number[] = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  // Create the blob object
  const blob = new window.Blob([new Uint8Array(array)], { type });

  return { blob, name };
}

export function rangeSpec(schema: SchemaObject) {
  const spec: { max?: number; min?: number; step?: number } = {};
  if (schema.multipleOf) {
    spec.step = schema.multipleOf;
  }
  if (schema.minimum || schema.minimum === 0) {
    spec.min = schema.minimum;
  }
  if (schema.maximum || schema.maximum === 0) {
    spec.max = schema.maximum;
  }
  return spec;
}
