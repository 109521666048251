import React, { forwardRef, memo } from 'react';

import useClassy from '@core/hooks/useClassy';

import './style.scss';

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  allCaps?: boolean;
  children?: React.ReactNode;
  circular?: boolean;
  className?: string;
  ghost?: boolean;
  kind?: 'alert' | 'callout' | 'dark' | 'failure' | 'info' | 'light' | 'success';
};

const Badge = memo(
  forwardRef(
    (
      { allCaps = false, children, circular = true, className, ghost = false, kind = 'light', ...attrs }: Props,
      ref?: React.ForwardedRef<HTMLElement>,
    ) => {
      const bem = useClassy(undefined, 'Badge');

      return (
        <span
          ref={ref}
          className={bem(
            className,
            '&',
            `_${kind}`,
            allCaps && '_all-caps',
            circular && '_circular',
            ghost && '_ghost',
          )}
          {...attrs}
        >
          {children}
        </span>
      );
    },
  ),
);

Badge.displayName = 'Badge';

export default Badge;
